import * as React from 'react';
import { observer, Provider } from 'mobx-react';
import { TransitionGroup, Transition } from 'react-transition-group';
import { TransitionProps } from 'react-transition-group/Transition';

import { DpeMainFooter } from './dpe-main-footer';
import { DpeMainHeader } from './dpe-main-header';
import { MainViewModel } from './main-view-model';
import * as styles from './main.less';

import { BrandingTheme } from '../branding/branding-theme';
import { ModalCommodore } from '../modals/modal-commodore';
import {
	panel as enterPledgePanel,
	footer as enterPledgeFooter,
} from '../pages/enter-pledge/enter-pledge.less';
import { EnterPledge } from '../pages/enter-pledge/enter-pledge';
import { EnterMobileNumber } from '../pages/enter-mobile-number/enter-mobile-number';
import { EnterSecurityCode } from '../pages/enter-security-code/enter-security-code';
import { CreateUser } from '../pages/create-user/create-user';
import { ConfirmPledge } from '../pages/confirm-pledge/confirm-pledge';
import { ConfirmCreatePledge } from '../pages/confirm-create-pledge/confirm-create-pledge';
import { PledgeExists } from '../pages/pledge-exists/pledge-exists';
import { PledgeSuccess } from '../pages/pledge-success/pledge-success';
import { States } from '../state-machine/states-events-actions';
import { generateManageAccountUrl } from '../utils/identity-utils';

import { ErrorBoundary } from '../../Shared/components/error-boundary';
import { Match } from '../../Shared/state-machine/match';
import { StateMachine } from '../../Shared/state-machine/saga-state-machine';
import { classNames } from '../../Shared/utils/classnames';

import { WebGivingMainBanner } from '../../WebGiving/main/banner/webgiving-main-banner';
import { Panel, PanelType } from '../../WebGiving/components/panel';
import { getInjectablePropsForProvider } from '../../WebGiving/utils/injectable-props';
import { NotificationController } from '../../WebGiving/components/notification/notification';
import { HeroImage } from '../../WebGiving/components/hero-image/hero-image';

const pageTransitionDuration = parseInt(styles.transitionDurationLongMs, 10);

export interface IDonorPledgeEntryProps {
	vm: MainViewModel;
}

@observer
export class Main extends React.Component<IDonorPledgeEntryProps> {
	render() {
		const { vm } = this.props;
		const {
			machineContext,
			person,
			isAppLoading,
			reportError,
			organization,
			pushpayInfo,
			theme,
			brandingPackage,
			scrollStateStore,
			isAccountDetailsOpen,
			setIsAccountDetailsOpen,
			raiseAction,
			handleUserSignOut,
			notification,
			isPage,
		} = vm;
		const heroImageUrl = brandingPackage && brandingPackage.HeroImageUrl;
		const transparentBackground = !!heroImageUrl && scrollStateStore.isAtTopOfThePage;
		const isEnterPledgePage = isPage(States.EnterPledgeAmount);

		return (
			<Provider {...getInjectablePropsForProvider(raiseAction)}>
				<div>
					<BrandingTheme theme={theme} />
					{heroImageUrl &&
						<ErrorBoundary onError={reportError}>
							<HeroImage supportsBackdrop={false} heroImageUrl={heroImageUrl} />
						</ErrorBoundary>
					}
					<ErrorBoundary onError={reportError}>
						<DpeMainHeader
							person={person}
							brandingPackage={brandingPackage}
							organization={organization}
							setIsAccountDetailsOpen={setIsAccountDetailsOpen}
							isAccountVisible={isAccountDetailsOpen}
							manageAccountUrl={generateManageAccountUrl(pushpayInfo, person)}
							handleUserSignOut={handleUserSignOut}
							onError={reportError}
							transparentBackground={transparentBackground}
							className={
								classNames(
									styles.header,
									{
										[styles.eclipsed]: isAccountDetailsOpen,
										[styles.loggedIn]: !!person,
									},
								)
							}
						/>
					</ErrorBoundary>
					<main className={classNames(styles.main, { [styles.loggedIn]: !!person, [`${styles.loading} brand-primary-before-color`]: isAppLoading })}>
						<ErrorBoundary onError={reportError}>
							<WebGivingMainBanner visible={isEnterPledgePage} className={heroImageUrl ? '' : 'brand-secondary-bg'} />
						</ErrorBoundary>
						<ErrorBoundary onError={reportError}>
							<NotificationController viewModel={notification} />
						</ErrorBoundary>
						<StateMachine machineContext={machineContext}>
							<TransitionGroup>
								<MachineTransition vm={this.props.vm} />
							</TransitionGroup>
						</StateMachine>
						<ErrorBoundary onError={reportError}>
							<DpeMainFooter
								organization={organization}
								pushpayInfo={pushpayInfo}
								className={isEnterPledgePage ? enterPledgeFooter : ''} />
						</ErrorBoundary>
					</main>
					<ErrorBoundary onError={reportError}>
						<ModalCommodore vm={vm} onError={reportError} />
					</ErrorBoundary>
				</div>
			</Provider>
		);
	}

	componentWillUnmount() {
		this.props.vm.history.destroy();
	}
}

function MachineTransition(props: { vm: MainViewModel } & Partial<TransitionProps>) {
	const { vm, ...rest } = props;
	const {
		machineContext,
		enterPledgeViewModel,
		enterMobileNumberViewModel,
		enterSecurityCodeViewModel,
		createUserViewModel,
		confirmPledgeViewModel,
		confirmCreatePledgeViewModel,
		pledgeExistsViewModel,
		pledgeSuccessViewModel,
		isPage,
	} = vm;

	return (
	<Transition {...rest} timeout={pageTransitionDuration}>
		{(state: any) => {
			const machineAndState = { machineContext, transitionState: state };
			const isEnterPledgePage = isPage(States.EnterPledgeAmount);

			return (
				<div className={classNames('container', styles.container)}>
					<Panel
						className={isEnterPledgePage ? enterPledgePanel : ''}
						panelType={isEnterPledgePage ? PanelType.White : PanelType.Highlight}
						responsive>
						<Match state={States.EnterPledgeAmount}>
							<EnterPledge {...machineAndState} vm={enterPledgeViewModel} />
						</Match>
						<Match state={States.EnterMobileNumber}>
							<EnterMobileNumber {...machineAndState} vm={enterMobileNumberViewModel} />
						</Match>
						<Match state={States.EnterSecurityCode}>
							<EnterSecurityCode {...machineAndState} vm={enterSecurityCodeViewModel} />
						</Match>
						<Match state={States.CreateUser}>
							<CreateUser {...machineAndState} vm={createUserViewModel} />
						</Match>
						<Match state={States.ConfirmPledge}>
							<ConfirmPledge {...machineAndState} vm={confirmPledgeViewModel} />
						</Match>
						<Match state={States.ReenterSecurityCode}>
							<ConfirmCreatePledge {...machineAndState} vm={confirmCreatePledgeViewModel} />
						</Match>
						<Match state={States.PledgeExists}>
							<PledgeExists {...machineAndState} vm={pledgeExistsViewModel} />
						</Match>
						<Match state={States.PledgeSuccess}>
							<PledgeSuccess {...machineAndState} vm={pledgeSuccessViewModel} />
						</Match>
					</Panel>
				</div>
			);
		}}
	</Transition>);
}
