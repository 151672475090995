import * as React from 'react';

import * as styles from './hero-image.less';

export interface IHeroImageProps {
	supportsBackdrop: boolean; // [FeatureFlagWillMakeThisCodeObsolete(FeatureName.EmbeddedGiving_WebGiving)]
	heroImageUrl: string;
	className?: string;
}

export class HeroImage extends React.Component<IHeroImageProps> {
	render() {
		const { heroImageUrl, className = '', supportsBackdrop } = this.props;
		const heroImageStyles: React.CSSProperties = {
			backgroundImage: `url(${heroImageUrl})`,
		};
		return (
			<div className={`${supportsBackdrop ? styles.container : styles.containerOld} ${className}`}>
				<div className={styles.gradients}></div>
				<div data-pp-at-target="Hero image" className={styles.image} style={heroImageStyles}></div>
			</div>
		);
	}
}
